import React from 'react';
import * as styles from './Impressum.module.css';


export default function Impressum() {
    return (
        <div className={styles.impressContainer}>
            <div className={styles.impressWrapper}>
                <h3 className={styles.impressHead}>Impressum</h3>
                <h4 className={styles.impressSubhead}>Angaben gemäß § 5 TMG</h4>
                <p className={styles.impressBody}>Tim Winthagen <br/>Schöllerstraße 43-47 <br/>52351 Düren</p>
                <h4 className={styles.impressSubhead}>Kontakt</h4>
                <p className={styles.impressBody}>Telefon: 0172 8455910 <br/>E-Mail: info@praxis-winthagen.de</p>
                <h4 className={styles.impressSubhead}>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</h4>
                <p className={styles.impressBody}>Tim Winthagen <br/>Schöllerstraße 43-47 <br/>52351 Düren</p>
                <h4 className={styles.impressSubhead}>EU-Streitschlichtung</h4>
                <p className={styles.impressBody}>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
                    https://ec.europa.eu/consumers/odr. Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
                <h4 className={styles.impressSubhead}>Verbraucherstreitbeilegung/Universalschlichtungsstelle</h4>
                <p className={styles.impressBody}>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                    Verbraucherschlichtungsstelle teilzunehmen.</p>
                <h4 className={styles.impressSubhead}>Haftung für Inhalte</h4>
                <p className={styles.impressBody}>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                    allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                    verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
                    forschen, die auf eine rechtswidrige Tätigkeit hinweisen. <br/>    
                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                    Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                    Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                    Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
                <h4 className={styles.impressSubhead}>Haftung für Links</h4>
                <p className={styles.impressBody}>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
                    Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
                    verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
                    Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
                    waren zum Zeitpunkt der Verlinkung nicht erkennbar. <br/>
                    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
                    Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                    umgehend entfernen.</p> 
                <h4 className={styles.impressSubhead}>Urheberrecht</h4>
                <p className={styles.impressBody}>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                    Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
                    Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                    Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
                    beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                    Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                    Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p> 
                <h4 className={styles.impressSubhead}>Bildnachweise:</h4>
                <p className={styles.impressBody}>
                    Bild von <a href="https://pixabay.com/de/users/whitesession-4645995/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2768834">Angelo Esslinger</a> auf <a href="https://pixabay.com/de/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2768834">Pixabay</a>
                    <br/>
                    Bild von <a href="https://pixabay.com/de/users/graceie-804741/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=650878">graceie</a> auf <a href="https://pixabay.com/de/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=650878">Pixabay</a>
                    <br/>
                    Bild von <a href="https://pixabay.com/de/users/whitesession-4645995/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2722936">Angelo Esslinger</a> auf <a href="https://pixabay.com/de/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2722936">Pixabay</a>
                    <br/>
                    Bild von <a href="https://pixabay.com/de/users/Bru-nO-1161770/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3082367">Bruno /Germany</a> auf <a href="https://pixabay.com/de/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3082367">Pixabay</a>
                    <br/>
                    Bild von <a href="https://pixabay.com/de/users/andreas160578-2383079/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2133286">andreas160578</a> auf <a href="https://pixabay.com/de/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2133286">Pixabay</a>
                    <br/>
                    Bild von <a href="https://pixabay.com/de/users/PublicDomainPictures-14/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=84646">PublicDomainPictures</a> auf <a href="https://pixabay.com/de/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=84646">Pixabay</a>
                    <br/>
                    Bild von <a href="https://pixabay.com/de/users/rodrigobernasc-5994140/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2538802">rodrigobernasc</a> auf <a href="https://pixabay.com/de/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2538802">Pixabay</a>
                    <br/>
                    Bild von <a href="https://pixabay.com/de/users/Photos_kast-11966122/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=4592957">Ioannis Ioannidis</a> auf <a href="https://pixabay.com/de/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=4592957">Pixabay</a> <br/>
                    Electro stimulation in physical therapy to a young woman <a href="http://www.freepik.com">Designed by javi_indy / Freepik</a>
                    <br/>
                    geraete0004.jpg - Photo by Febrian Zakaria on Unsplash</p> 

                <p className={styles.impressBody}>Impressum Quelle: https://www.e-recht24.de</p>

            </div>
        </div>
    )
}
