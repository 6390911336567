import React from "react";
import Helmet from 'react-helmet';
import Layout from "../components/layout";
import Impressum from "../components/Impressum_";
import Info from "../components/Info";

const ImpressumPage = () => {
  return (
    <>
      <Helmet>
        <title>Impressum</title>
        <meta name='description' content='Impressum der Physiotherapie Praxis Tim Winthagen mit Infos rund um Ihre Behandlung'/>
        <meta name='keywords' content='Physiotherapie, Krankengymnastik, Manuelle Lymphdrainage, Fußreflexzonenmassage, Wärmetherapie, Kältetherapie, Medizinische Massagetherapie, Neurologische Behandlung, Bobath Erwachsene, Faszienbehandlung, Elektrotherapie, Ultraschall, Flossing, Gerätetraining, Hausbesuche, Düren'/>
        <meta property='og:title' content='Impressum'/>
        <meta property='og:type' content='Website'/>
        <meta property='og:description' content='Impressum der Physiotherapie Praxis Tim Winthagen mit Infos rund um Ihre Behandlung'/>
        <meta property='og:image' content=''/>
        <meta property='og:locale' content='de'/>
        <meta property='og:url' content='https://www.physiotherapie-winthagen.de/impressum'/>
        <link rel="canonical" href="https://www.physiotherapie-winthagen.de/impressum"/>
      </Helmet>



      <Layout>
          <Impressum></Impressum>
          <Info />
      </Layout>
    </>  
  )
}

export default ImpressumPage